import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { NewAuthDataService } from '../../../../../service/api/newAuth-data.service';
import { GroupsDataService } from '../../../../../store/group/groups-data.service';
import { ToastrService } from 'ngx-toastr';
import { FuseProgressBarService } from '../../../../../../@fuse/components/progress-bar/progress-bar.service';
import { Router } from '@angular/router';
import { GroupUsersEntityService } from '../../../../../store/group/GroupUsers/group-users-entity.service';
import { GroupsEntityService } from '../../../../../store/group/groups-entity.service';
import { UtilityService } from '../../../../../service/utility.service';
import { IClinicContact } from '../../../../../types/ClinincData';

@Component({
    selector: 'group-invite',
    templateUrl: './group-invite.component.html',
    styleUrls: ['./group-invite.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class GroupInviteComponent implements OnInit, OnDestroy {
    inviteForm: FormGroup;
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    params: any;
    readonly separatorKeysCodes = [ENTER, COMMA] as const;
    userEmails: any = [];
    userNumbers: any = [];
    groupsList: any;
    selectedGroups: any = [];
    user: string;
    inviteData: any;
    showIcon: false;
    currentClinic: any;
    currentGroup: any;
    page = 0;
    totalContacts: number;
    contacts: IClinicContact[] = [];
    selectedContact: any;

    constructor(public matDialogRef: MatDialogRef<GroupInviteComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _matDialog: MatDialog,
        private _formBuilder: FormBuilder,
        private authDataService: NewAuthDataService,
        private groupDataService: GroupsDataService,
        private groupUsersEntityService: GroupUsersEntityService,
        private groupEntity: GroupsEntityService,
        private toast: ToastrService,
        private router: Router,
        private utilityService: UtilityService,
        private progressBarService: FuseProgressBarService,
        private groupService: GroupsDataService,
    ) {
    }

    ngOnInit(): void {
        this.currentGroup = this._data.currentClinic;
        this.inviteData = this._data.user;
        this.showIcon = this._data.profileShown;
        this.groupDataService.castUser.subscribe(user => this.user = user);
        this.inviteForm = this._formBuilder.group({
            email: [this.user],
            phone: [''],
            patientBoardMessage: [''],
            countryCode: ['32132122'],
            selectedContact: [],
        });
        if (this.showIcon) {
            this.userEmails.push({ value: this.inviteData.email, invalid: false });
        }
        this.groupDataService.getUserGroups().subscribe((data) => {
            this.groupsList = data.clinics;

        });
        this.params = this.utilityService.collectParams(this.router.routerState.snapshot.root)?.id;
        this.groupEntity.entities$.subscribe((groups) => {
            this.currentClinic = groups.find(o => +o.id === +this.params);
        });
        this.myDocContacts();
    }

    ngOnDestroy(): void {
    }

    myDocContacts(): void {
        const params = {
            'page': ++this.page,
            'limit': 10,
        };
        this.groupService.getUserContacts(params).subscribe((data: any) => {
            this.totalContacts = data?.total_contacts;
            for (const contact of data.clinic_contacts) {
                contact.contact_name = this.groupService.capitalizeFirstLetter(contact.contact_name ?? '');
            }
            // this.sortingContacts(data.clinic_contacts);

            this.contacts = [...this.contacts, ...data.clinic_contacts];
            // this.allContacts = [...this.allContacts, ...data.clinic_contacts];
        }, (error) => {
            this.toast.error(error.errors.join(', '), 'Error');
        });
    }
    onContactSelected(): void {
        this.page = 0;
        this.myDocContacts();
        if (this.inviteForm.get('selectedContact')?.value) {
            this.userNumbers.push({ value: this.inviteForm.get('selectedContact')?.value, invalid: false });
            this.inviteForm.value.selectedContact = '';
        }
    }
    add(event: MatChipInputEvent): void {
        if (event.value) {
            if (this.validateEmail(event.value)) {
                this.userEmails.push({ value: event.value, invalid: false });
            } else {
                this.toast.error('Please Enter Valid Email Address', 'Error');
            }
            event.input.value = '';
        }
    }
    addNumber(event: MatChipInputEvent): void {
        if (event.value) {
            if (this.validateNumber(event.value)) {
                this.userNumbers.push({ value: '+45' + event.value, invalid: false });
            } else {
                this.toast.error('Please Enter Valid Phone Number', 'Error');
            }
            event.input.value = '';
        }
    }

    remove(data: any, identifier: string): void {
        switch (identifier) {
            case 'EMAIL':
                if (this.userEmails.indexOf(data) >= 0) {
                    this.userEmails.splice(this.userEmails.indexOf(data), 1);
                }
                break;
            case 'NUMBER':
                if (this.userNumbers.indexOf(data) >= 0) {
                    this.userNumbers.splice(this.userNumbers.indexOf(data), 1);
                }
                break;
            default:
                break;
        }
    }

    private validateEmail(email): boolean {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    private validateNumber(phone): boolean {
        const regex = /^[0-9]{8}$/;
        return regex.test(String(phone).toLowerCase());
    }

    showOptions(event, index): void {
        if (event.checked && this.selectedGroups.indexOf(this.groupsList[index]) === -1) {
            this.selectedGroups.push(this.groupsList[index]);
        } else {
            index = this.selectedGroups.indexOf(this.groupsList[index]);
            this.selectedGroups.splice(index, 1);
        }
    }

    sendInvite(): void {
        if (this.userEmails.length > 0 || this.userNumbers.length > 0) {
            const tempSelectedGroups: any = [];
            for (const selectedGroup of this.selectedGroups) {
                tempSelectedGroups.push(selectedGroup.id);
            }
            const tempSelectedUsers: any = [];
            for (const userEmail of this.userEmails) {
                tempSelectedUsers.push(userEmail.value);
            }
            for (const userNumber of this.userNumbers) {
                tempSelectedUsers.push(userNumber.value);
            }
            const emailData = {
                clinic_ids: [this._data.currentClinic.id],
                users: tempSelectedUsers,
                message: this.inviteForm.get('patientBoardMessage')?.value
            };
            this.progressBarService.show();
            this.groupDataService.inviteUsersToGroups(emailData).subscribe((data) => {
                // here invite
                this.toast.success('', data.message);
                this.progressBarService.hide();
                if (this.params && tempSelectedGroups.includes(+this.params) && data.clinic_users.length > 0) {
                    this.groupUsersEntityService.addManyToCache(data.clinic_users);
                    this.groupEntity.updateOneInCache({
                        joinedMembers: this.currentClinic.joinedMembers + +data.clinic_users.length,
                        id: this.currentClinic.id
                    });
                } else {
                    this.groupUsersEntityService.addManyToCache(data.clinic_users);
                    this.groupEntity.updateOneInCache({
                        joinedMembers: this.currentClinic.joinedMembers + +data.clinic_users.length,
                        id: this.currentClinic.id
                    });

                }
                this.matDialogRef.close('Invite Sent');
            }, (error) => {
                this.progressBarService.hide();
                this.toast.error(error.errors.join(', '), 'Error');
            });



        } else {
            this.toast.error('Please Enter Some User email or number First', 'Error');
        }

    }
}
