<form novalidate [formGroup]="postForm">
    <div [ngSwitch]="post?.docType" fxLayout="row" class="preheader" fxLayoutAlign="space-between start"
        [ngStyle]="{'background-color' : getStyle(post)}">
        <div class="user" fxLayout="row" fxLayoutAlign="start center">
            <!-- <profile-image [userProfile]="post.docCreator?.id" class="avatar cursor-pointer"
                           [src]="post.docCreator?.file?.thumb_url"></profile-image> -->
            <div fxLayout="column">
                <!-- <div class="title">
                    <span [userProfile]="post.docCreator?.id"
                          class="username cursor-pointer">{{post.docCreator?.full_name ? post.docCreator?.full_name : '' }}</span>
                    <span *ngSwitchCase="'thread'" class="time ml-8"
                          [ngStyle]="{'font-weight': 400}">{{post?.createdTime | amTimeAgo}}</span>
                    <span *ngSwitchCase="'task'" class="time ml-8"
                          [ngStyle]="{'font-weight': 400}">{{post?.taskStart | amTimeAgo}}</span>
                </div> -->
                <div *ngSwitchCase="'thread'" class="time"><span class="cursor-pointer "
                        routerLink="/groups/{{post?.clinicId}}">{{ post?.clinicName }}
                    </span></div>
                <div *ngSwitchCase="'time_tracker'" class="time" [ngStyle]="{'font-weight': 600}"><span
                        class="cursor-pointer " routerLink="/groups/{{post?.clinicId}}">{{ post?.clinicName }}
                    </span></div>
                <span class="time post-title"
                    [ngStyle]="{'font-weight': post?.docType === 'time_tracker'  ? 600 : 'normal'}">{{
                    post?.categoryName }}</span>
                <div *ngSwitchCase="'task'" class="time"><span class="cursor-pointer "
                        routerLink="/groups/{{post?.clinicId}}">{{ post?.clinicName }}
                    </span></div>
                <!-- <span class="time post-title" [innerHTML]="post?.title"></span> -->
            </div>
        </div>
        <div fxLayout="row">
            <ng-container *ngSwitchCase="'thread'">

                <button *ngIf="post?.isAdmin && post.postPinned" (click)="pinThreadInChat()" class="mr-4"
                    mat-icon-button aria-label="More" matTooltip="{{'post.pin_post' | translate}}">
                    <mat-icon>chat</mat-icon>
                </button>

                <button *ngIf="post?.isAdmin && !post.postPinned" (click)="pinThreadInChat()" class="mr-4"
                    mat-icon-button aria-label="More" matTooltip="{{'post.unpin_post' | translate}}">
                    <mat-icon>speaker_notes_off</mat-icon>
                </button>
                <button class="" mat-icon-button aria-label="More" matTooltip="{{'THREAD.share' | translate}}"
                    (click)="shareThread()">
                    <mat-icon class="share-icon mr-4">reply</mat-icon>
                    <!-- <span class="mt-12 mr-4   share-text ">{{'THREAD.share' | translate}}</span> -->
                </button>

                <button *ngIf="!post?.docNotification" class="mr-4" mat-icon-button aria-label="More"
                    matTooltip="{{'post.thread_notification_off' | translate}}" (click)="turnThreadNotificationOn()">
                    <mat-icon>notifications_off</mat-icon>
                </button>
                <button *ngIf="post?.docNotification" class="mr-4" mat-icon-button aria-label="More"
                    matTooltip="{{'post.thread_notification_on' | translate}}" (click)="turnThreadNotificationOff()">
                    <mat-icon>notifications_active</mat-icon>
                </button>

                <!-- <button *ngIf=" post?.isAdmin || this.threadAuthor && !post?.isFileBeingUploaded" mat-icon-button
                        [matMenuTriggerFor]="postMenu"
                        aria-label="More">
                    <mat-icon>more_vert</mat-icon>
                </button> -->
            </ng-container>
            <ng-container *ngSwitchCase="'time_tracker'">
                <button class="mr-12" mat-icon-button aria-label="" matTooltip="" (click)="openCardDialog(post)">
                    <mat-icon class="location-icon">sms</mat-icon>
                </button>
                <button class="mr-12" mat-icon-button aria-label="location" matTooltip="{{'post.location' | translate}}"
                    (click)="openMap()" *ngIf="currentClinic?.clinicModules?.use_gps_location">
                    <mat-icon class="location-icon">location_on</mat-icon>
                </button>
            </ng-container>
            <!-- <button *ngSwitchCase="'task'" mat-icon-button [matMenuTriggerFor]="postMenu"
                    aria-label="More">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #postMenu="matMenu">
                <button *ngSwitchCase="'task'" mat-menu-item (click)="editTask(post)">
                    <mat-icon>edit</mat-icon>
                    <span>{{'DASHBOARD_TASK.edit' | translate}}</span>
                </button>
                <ng-container *ngSwitchCase="'thread'">
                    <button mat-menu-item (click)="editThread()" *ngIf="!post?.isAdmin || threadAuthor">
                        <mat-icon>edit</mat-icon>
                        <span>{{'DASHBOARD_TASK.edit' | translate}}</span>
                    </button>
                    <button mat-menu-item (click)="deleteThread()" *ngIf="post?.isAdmin || threadAuthor">
                        <mat-icon>delete</mat-icon>
                        <span>{{'DASHBOARD_TASK.delete' | translate}}</span>
                    </button>
                </ng-container>
            </mat-menu> -->
        </div>
    </div>
    <header [ngSwitch]="post?.docType" fxLayout="row" fxLayoutAlign="space-between start" class="updateddesign">
        <div class="user " fxLayout="row" fxLayoutAlign="start center">
            <profile-image [userProfile]="post.docCreator?.id" class="avatar cursor-pointer"
                [src]="post.docCreator?.file?.thumb_url"></profile-image>

            <div fxLayout="column">
                <div class="title">
                    <span [userProfile]="post.docCreator?.id"
                        class="username cursor-pointer">{{post.docCreator?.full_name ? post.docCreator?.full_name : ''
                        }}</span>
                </div>
                <span *ngSwitchCase="'thread'" class="time" [ngStyle]="{'font-weight': 400}">{{post?.createdTime |
                    amTimeAgoCustom
                    | async}}</span>
                <span *ngSwitchCase="'task'" class="time" [ngStyle]="{'font-weight': 400}">{{post?.taskStart |
                    amTimeAgoCustom |
                    async}}</span>
                <span *ngSwitchCase="'time_tracker'" class="time" [ngStyle]="{'font-weight': 400}">{{post?.createdTime |
                    amTimeAgoCustom
                    | async}} <span *ngIf="post?.taskStart && !post?.taskEnd">{{ 'THREAD.check_in' | translate }}</span>
                    <span *ngIf="post?.taskEnd">{{ 'THREAD.check_out' | translate }}</span></span>
                <!-- <div *ngSwitchCase="'thread'" class="time"><span
                        class="cursor-pointer group-name-color"
                        routerLink="/groups/{{post?.clinicId}}">{{ post?.clinicName }}
                    | {{ post?.categoryName }} </span></div>
                <div *ngSwitchCase="'task'" class="time"><span
                        class="cursor-pointer group-name-color"
                        routerLink="/groups/{{post?.clinicId}}">{{ post?.clinicName }}
                </span></div> -->
            </div>
        </div>
        <div fxLayout="row">
            <ng-container *ngSwitchCase="'thread'">
                <!-- <button *ngIf="post?.isAdmin && post.postPinned" (click)="pinThreadInChat()" class="mr-4"
                        mat-icon-button aria-label="More"
                        matTooltip="{{'post.pin_post' | translate}}"
                        >
                    <mat-icon>chat</mat-icon>
                </button>

                <button *ngIf="post?.isAdmin && !post.postPinned" (click)="pinThreadInChat()" class="mr-4"
                        mat-icon-button aria-label="More"
                        matTooltip="{{'post.unpin_post' | translate}}"
                >
                    <mat-icon>speaker_notes_off</mat-icon>
                </button>
                <button class="mr-24" mat-icon-button aria-label="More"
                        matTooltip="{{'THREAD.share' | translate}}" (click)="shareThread()">
                    <mat-icon class="share-icon mr-4">reply</mat-icon>
                    <span class="mt-12 mr-4   share-text ">{{'THREAD.share' | translate}}</span>
                </button>

                <button *ngIf="!post?.docNotification" class="mr-4" mat-icon-button aria-label="More"
                        matTooltip="{{'post.thread_notification_off' | translate}}"
                        (click)="turnThreadNotificationOn()">
                    <mat-icon>notifications_off</mat-icon>
                </button>
                <button *ngIf="post?.docNotification" class="mr-4" mat-icon-button aria-label="More"
                        matTooltip="{{'post.thread_notification_on' | translate}}"
                        (click)="turnThreadNotificationOff()">
                    <mat-icon>notifications_active</mat-icon>
                </button> -->

                <button *ngIf=" post?.isAdmin || this.threadAuthor && !post?.isFileBeingUploaded" mat-icon-button
                    [matMenuTriggerFor]="postMenu" aria-label="More">
                    <mat-icon>more_vert</mat-icon>
                </button>
            </ng-container>
            <ng-container *ngSwitchCase="'time_tracker'">
                <div fxLayout="column">
                    <p class="check-time" *ngIf="post?.taskStart && !post?.taskEnd"
                        (click)="editTime(post,post?.taskStart,'task_start')">{{taskStart}}</p>
                    <p class="check-time" *ngIf="post?.taskEnd" (click)="editTime(post,post?.taskEnd,'task_end')">
                        {{taskEnd}}</p>
                    <span *ngIf="post?.modified" [ngStyle]="{'font-weight': 800}"
                        matTooltip="{{'post.check_time_status' | translate}}">{{'post.check_time_status' |
                        translate}}</span>
                </div>
            </ng-container>
            <button *ngSwitchCase="'task'" mat-icon-button [matMenuTriggerFor]="postMenu" aria-label="More">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #postMenu="matMenu">
                <button *ngSwitchCase="'task'" mat-menu-item (click)="editTask(post)">
                    <mat-icon>edit</mat-icon>
                    <span>{{'DASHBOARD_TASK.edit' | translate}}</span>
                </button>
                <ng-container *ngSwitchCase="'thread'">
                    <button mat-menu-item (click)="editThread()" *ngIf="!post?.isAdmin || threadAuthor">
                        <mat-icon>edit</mat-icon>
                        <span>{{'DASHBOARD_TASK.edit' | translate}}</span>
                    </button>
                    <button mat-menu-item (click)="deleteThread()" *ngIf="post?.isAdmin || threadAuthor">
                        <mat-icon>delete</mat-icon>
                        <span>{{'DASHBOARD_TASK.delete' | translate}}</span>
                    </button>
                </ng-container>
            </mat-menu>
        </div>
    </header>
    <div *ngIf="post?.docType === 'time_tracker' && post?.clinicCategory.name === 'Timetrack exports'">
        <div class="content" [ngSwitch]="post?.docType">
            <div observeVisibility [debounceTime]="0" (visible)="onAppear()" *ngIf="post?.description" class="message"
                fxLayout="column">
                <span class="time post-title" [innerHTML]="post?.title"></span>
                <app-linkify-read-more [limit]="250" [content]="post?.description"></app-linkify-read-more>
            </div>
            <div class="container">

            </div>
            <div *ngIf="!post.isFileBeingUploaded" class="media">
                <app-gallery [items]="post?.files"></app-gallery>
            </div>
            <div *ngIf="post?.isFileBeingUploaded" class="file-upload">
                Uploading files, Please wait..
            </div>
            <div *ngIf="post?.attachingFile === true" class="file-upload">
                Loading files, Please wait..
            </div>

        </div>
    </div>
    <ng-container *ngIf="post?.docType !== 'time_tracker'">
        <div class="content" [ngSwitch]="post?.docType">
            <div observeVisibility [debounceTime]="0" (visible)="onAppear()" *ngIf="post?.description" class="message"
                fxLayout="column">
                <span class="time post-title" [innerHTML]="post?.title"></span>
                <app-linkify-read-more [limit]="250" [content]="post?.description"></app-linkify-read-more>
            </div>
            <div class="container">

            </div>
            <div *ngIf="!post.isFileBeingUploaded" class="media">
                <app-gallery [items]="post?.files"></app-gallery>
            </div>
            <div *ngIf="post?.isFileBeingUploaded" class="file-upload">
                Uploading files, Please wait..
            </div>
            <div *ngIf="post?.attachingFile === true" class="file-upload">
                Loading files, Please wait..
            </div>
            <is-file-uploading [threadID]="post?.id" (isUploading)="isFilesUploading($event)"></is-file-uploading>
            <div fxLayout="row" fxLayoutAlign="end center" *ngSwitchCase="'thread'">
                <button mat-button class="share-button"
                    *ngIf="post.priority !== null && post?.priority !== 'nothing' && post.priority !== 'normal'">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon class="s-16">label</mat-icon>
                        <span *ngIf="post?.priority === 'notice'" class="notice-priority">{{'post.notice' |
                            translate}}</span>
                        <span *ngIf="post?.priority === 'important'" class="important-priority">{{'post.important' |
                            translate}}</span>
                        <span *ngIf="post?.priority === 'critical'" class="critical-priority">{{'post.critical' |
                            translate}}</span>
                    </span>
                </button>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" *ngSwitchCase="'task'">
                <button mat-button class="share-button">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <span *ngIf="post?.taskStatus === 'in_progress'" class="notice-priority">In Progress</span>
                        <span *ngIf="post?.taskStatus === 'to_do'" class="important-priority">To do</span>
                        <span *ngIf="post?.taskStatus === 'done'" class="green-priority">Done</span>
                    </span>
                </button>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div>
                    <button mat-button class="like-button" (click)="setFooterType(FooterType.Comment)">
                        <span fxLayout="row" fxLayoutAlign="start center">
                            <span>{{'TASKS.comment' | translate}}</span>&nbsp;<span>({{post.clinicDocCommentsCount}}
                                )</span>
                        </span>
                    </button>
                    <button mat-button class="share-button" (click)="setFooterType(FooterType.File)">
                        <span fxLayout="row" fxLayoutAlign="start center">
                            <span>{{'TASKS.file' | translate}}</span>&nbsp;<span>({{ post?.clinicDocFileCount +
                                post?.clinicDocCommentFileCount }}
                                )</span>
                        </span>
                    </button>
                </div>
                <comment-read [id]="post?.id" [type]="readType?.doc" class="mr-16"></comment-read>
            </div>

        </div>
    </ng-container>
    <post-footer [post]="post" [type]="showFooterType"></post-footer>
</form>